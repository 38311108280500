/* You can add global styles to this file, and also import other style files */
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");
@import url("https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,700italic,700,500&subset=latin,latin-ext");

@import "swiper/css";
@import "swiper/swiper-bundle.css";
/* @import "swiper/components/effect-fade/effect-fade.min.css"; */

@font-face {
  font-family: "Lato-thin";
  src: url("assets/Lato/Lato-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("assets/Lato/Lato-Black.ttf");
  src: url("assets/Lato/Lato-Light.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-regular";
  src: url("assets/Lato/Lato-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-bold";
  src: url("assets/Lato/Lato-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tesla";
  src: url("assets/Lato/tesla.ttf");
  font-weight: normal;
  font-style: normal;
}

:root {
  --primary-light: #00b7d5;
  --primary-lighter: #04a3bb;
  --primary-dark: #017dab;
  --text-color: #908f8c;
}

body,
html {
  color: #000;
  font-family: "lato" !important;
  height: 100%;
  letter-spacing: 0.025rem;
  font-family: "Lato", sans-serif !important;
  scroll-behavior: smooth;
}
@media (max-width: 1140px) {
  section {
    overflow-x: hidden;
  }
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.pt-8 {
  padding-top: 8rem !important;
}
.pb-8 {
  padding-bottom: 8rem !important;
}

.mt-8 {
  margin-top: 8rem !important;
}
.mb-8 {
  margin-bottom: 8rem !important;
}
.mt-10 {
  margin-top: 16rem !important;
}
.mb-10 {
  margin-bottom: 16rem !important;
}
h1 {
  font-family: "lato-regular" !important;
  font-size: 3rem !important;
  line-height: 2.5rem !important;
}
h2 {
  font-size: 2rem !important;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
}

.overlay {
  background-blend-mode: overlay;
  background: rgba(82, 80, 80, 0.897);
}
.icon-arrow:before {
  content: "\ea3c";
}
.indust-wrap a h3,
.design-wrap a h3 {
  font-size: 26px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 0;
  font-family: "Lato-regular", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  /* speak: never; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.logo-color {
  color: #00b8da !important;
}
.know-more {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
}
.know-more i {
  position: relative;
  top: 1px;
  left: -16px;
  opacity: 0;
  transition: all 0.3s;
}
.know-more:hover {
  text-decoration: none;
  font-size: 16px;
  transition: 0.3s ease-in-out;
}
.know-more:hover i {
  opacity: 1;
  left: 5px;
}
.banner-text {
  font-weight: 600;
  bottom: 0px;
  text-transform: none !important;
  /* margin-top: 35vh; */
  margin-top: 62vh;
}
.banner {
  height: 95vh !important;
}
.min-banner {
  height: 62vh !important;
}
.banner-text h1 {
  color: #ffffff;
  font-weight: 300;
  font-family: "Lato";
  font-style: normal;
  letter-spacing: 1px;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
}
.banner-text h4 {
  color: #ffffff;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  line-height: 30px;
  margin-top: 10px;
  text-align: left;
}

.banner-text h3 {
  line-height: 40px;
  text-transform: uppercase;
}
.banner-text h6 {
  line-height: 6px;
  font-size: 0.8rem;
}

.design-content p {
  font-weight: 400;
  font-size: 15px;
  font-family: "Lato", sans-serif;
}
.design-content h1 {
  font-size: 36px;
  font-family: "Lato", sans-serif;
  color: #908f8c;
}
.design-content span {
  color: var(--primary-light);
}
.column {
  float: left;
  width: 40%;
  padding: 20px 100px 100px 80px;
  height: auto;
}
.indust-info h5 {
  font-size: 15px;
  font-weight: bolder;
  color: #2e3a4d;
  text-transform: uppercase;
}
.indust-info p {
  font-size: 12px;
  color: #2e3a4d !important;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
}
.indust-info a {
  font-weight: 800;
  position: absolute;
  bottom: 10px;
}

.indust-wrap img {
  width: 100%;
  height: 30vh;
  border-radius: 10px;
  box-shadow: 0 0 3px #a0a0a0;
}
.indust-info {
  background-color: #fff;
  padding: 12px 15px 10px;
  box-shadow: 0.5px 0.5px 3px black;
  border-radius: 8px;
  position: relative;
  width: 80%;
  margin: -55px auto 0;
  min-height: 170px;
}

.image {
  height: 150px !important;
}
.bg-yellow {
  background-color: var(--primary-lighter);
}
.bg-yellow-med {
  background-color: var(--primary-light);
}
.bg-yellow-dark {
  background-color: var(--primary-dark);
}
.bg-orange {
  background-color: var(--primary-dark);
}

.bg-primary-light {
  background-color: #00b7d5;
}

.bg-primary-dark {
  background-color: #017dab;
}

.primary-grey {
  color: var(--text-color) !important;
}

.primary-grey-bg {
  background-color: var(--text-color) !important;
}

@media screen and (max-width: 576px) {
  h2,
  h1 {
    font-size: 24px !important;
    line-height: 40px !important;
  }
}

.lato-bold {
  font-family: "Lato-bold", sans-serif;
}
.lato-reg {
  font-family: "Lato-regular", sans-serif;
}
#core-services h2 {
  color: #323232;
}
#core-services p {
  color: #646464;
}
.core-services-wrap {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0px 2px #777;
}
.core-services-wrap img {
  width: 100%;
  height: 30vh;
  border-radius: 10px 10px 0 0;
}
.core-services-wrap a div {
  color: #444;
  font-size: 22px;
  padding: 20px;
  line-height: normal;
  text-transform: uppercase;
}
.core-services-wrap a:hover {
  text-decoration: none;
}

.drop-shadow {
  transition: 0.5s ease-in-out;
}
.drop-shadow:hover {
  box-shadow: 4px 7px #0000002a;
  transition: 0.5s ease-in-out;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
}
.animated-banner.swiper-slide.swiper-slide-visible.swiper-slide-active .banner-line{
  width: 0 !important;
}
